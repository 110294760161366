import { createDirectus, graphql } from '@directus/sdk';
import Collections from '../types/Collections';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL!;

const client = createDirectus<Collections>(API_BASE_URL).with(graphql());

export const fetchProductData = async () => {
  try {
    const result = await client.query<Collections>(`
      query {
        Produkte {
          id
          status
          user_created
          date_created
          user_updated
          date_updated
          Name
          Preis
          mitPfand
          Kategorie {
              id
              status
              Bezeichnung
              Color
              sort
          }
        }
      }
    `);

    result.Produkte.sort((a,b) => a.Kategorie.sort - b.Kategorie.sort)

    return result.Produkte;
  } catch (error) {
    throw error;
  }
};