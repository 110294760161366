import { useEffect, useState } from "react";
import { fetchProductData } from "../services/apiService";
import Product from "../types/Product";
import CartItem from "../types/CartItem";

const CalculatorPage:React.FC = () => {
    const [products, setProducts] = useState<Array<Product> | null>(null);
    const [summe, setSumme] = useState<number>(0);
    const [cart, setCart] = useState<Array<CartItem>>([]);

    async function fetchProducts() {
        try{
            const result = await fetchProductData();
            setProducts(result as Array<Product>);
        }catch(e){
            console.error('Error fetching data: ' + e);
        }
    } 

    useEffect(() => {
        fetchProducts();
    }, []);

    function addToCart(name:string, price:number, mitPfand:boolean){
        let newCartItem:CartItem = {
            name,
            price
        }

        if(mitPfand){
            cart.unshift({
                name: "Pfand",
                price: 2
            })
        }

        cart.unshift(newCartItem);
        setCart(cart);
        
        setSumme(cart.reduce((n, {price}) => n + price, 0));
    }
    
    function resetCart(){
        setCart([]);
        setSumme(0);
    }

    return (
        <div className="min-w-screen min-h-screen bg-gray-100 flex items-center justify-center p-2 md:p-8">
            <div className="w-full max-w-screen-md mx-auto rounded-xl bg-gray-100 shadow-xl text-gray-800 relative overflow-hidden" >
                <div className="bg-gradient-to-b from-gray-800 to-gray-700 ">
                    <div className="w-full h-30 flex items-end text-right">
                        <div className="w-full py-5 px-6 text-6xl text-white font-thin">{(Math.round(summe * 100) / 100).toFixed(2)} €</div>
                    </div>
                    <div className="w-full h-20 text-right overflow-y-scroll">
                        {cart && cart?.map((product, index) => {   
                            return <div className={`w-full px-6 ${product.price < 0 ? 'text-green-400' : 'text-white'} font-thin`} key={index}>{product.name} ({(Math.round(product.price * 100) / 100).toFixed(2)} €)</div>  
                        })}
                    </div>
                </div>
                <div className="w-full bg-gradient-to-b from-gray-800 to-gray-700 ">
                    <button className={`h-16 w-full text-black font-thin px-4 py-1 bg-gradient-to-tl from-yellow-400 to-yellow-500`} onClick={() => resetCart()}> Zurücksetzen </button>                    
                    <div className="grid grid-cols-3 md:grid-cols-4 w-full">
                        {products && products?.map((product, index) => {
                            let from = `from-${product.Kategorie.Color}-400`;
                            let to = `to-${product.Kategorie.Color}-500`;

                            return (<>
                                <div className={"border-r border-b border-indigo-400"} key={index} id={product.id} style={{overflowWrap: "break-word"}}>
                                    <button className={`w-full h-16 text-base md:text-xl outline-none focus:outline-none text-white font-light bg-gradient-to-tl ${from} ${to}`} onClick={() => addToCart(product.Name, product.Preis, product.mitPfand)}>{product.Name} <br /> ({(Math.round(product.Preis * 100) / 100).toFixed(2)} €)</button>
                                </div>
                            </>)
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalculatorPage;